const staticTemplates = {
    article_name: [
        {internal_description: "Plugin-Subscription Verlängerung Shopware Store", article_name: "Lizenzgebühr zur Shopware Plugin-Subscription Verlängerung: {PLUGINNAME}", description: 'Verlängerung um XX Monate, Bestelldatum XX.XX.XX.'},
        {internal_description: "Monatliche Weiterentwicklung mit Liste der Aufgaben", article_name: "Monatliche Weiterentwicklung des XXXXXXX Online-Shops im vereinbarten Zeitkontingent von XXXX Std./Monat: Leistungszeitraum: MONAT/JAHR", description: 'Unsere Aufgaben: <ul><li>Aufgabe 1</li><li>Aufgabe 2</li></ul>'},
        {internal_description: "Entwicklung der Funktion XZY", article_name: "Entwicklung der Funktion XZY", description: ''},

    ]

};

export default staticTemplates;
