import ApiService from "../../api";

class ArticleCustomPricesService {

    constructor() {
        this.clientId = null;
        this.customPrices = [];
    }

    fetchCustomerPrices(clientId) {
        this.clientId = clientId;
        this.customPrices = [];

        return ApiService.get(`article_custom_prices?filter[0][field]=client_id&filter[0][expression]=exact&filter[0][value]=${this.clientId}`).then((response) => {

            if (response.status !== 200) {
                return false;
            }

            this.customPrices = response.data.result.result;
        });
    }

    getPriceByArticleId(articleId) {
        if(this.customPrices.length < 1 || !Array.isArray(this.customPrices)) {
            return null;
        }

        const price = this.customPrices.find((item) => item.article_id === articleId);

        if(price === null || price === undefined) {
            return null;
        }

        return price;
    }

}

export default ArticleCustomPricesService;