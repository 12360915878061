<template>
  <div class="accounting-add" v-if="fetched">
    <div class="vx-row mb-base">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card title="Abrechnung" class="h-full" actionButtons>

          <template slot="actions">
            <vs-select v-model="type" @change="onTypeChange">
              <vs-select-item :key="index" :value="item.value" :text="item.label"
                              v-for="(item, index) in typeOptions"/>
            </vs-select>
          </template>

          <transition name="fade" mode="out-in" duration="200">
            <div v-if="type === 0" :key="0">
              <mg-select v-model="accounting.contract_id"
                         :options="contracts"
                         name="contract"
                         placeholder="Vertrag auswählen"
                         track-by="id"
                         label="id"
                         data-vv-as="Vertrag"
                         v-validate="'required'"
                         :custom-label="customContractLabel"
                         @select="onContractChange">
              </mg-select>
              <span class="text-danger text-sm" v-if="errors.has('contract')">{{ errors.first('contract') }}</span>

              <div class="vx-row flex mt-4">
                <div class="vx-col w-1/2">
                  <div class="flat-pickr-label-group">
                    <label class="text-sm ml-1">Vertragsbeginn</label>
                    <flat-pickr class="w-full" v-model="accounting.contract_start"
                                name="contract_start"
                                placeholder="Datum auswählen"></flat-pickr>
                  </div>
                </div>
                <div class="vx-col w-1/2">
                  <div class="flat-pickr-label-group">
                    <label class="text-sm ml-1">Vertragsende</label>
                    <flat-pickr class="w-full" v-model="accounting.contract_end"
                                name="contract_start"
                                placeholder="Datum auswählen"></flat-pickr>
                  </div>
                </div>
              </div>
              <div class="vx-row flex mt-4">
                <div class="vx-col w-1/2">
                  <div class="flat-pickr-label-group">
                    <label class="text-sm ml-1">Abrechnungsbeginn</label>
                    <flat-pickr class="w-full" v-model="accounting.accounting_start"
                                name="accounting_start"
                                placeholder="Datum auswählen"></flat-pickr>
                  </div>
                </div>
                <div class="vx-col w-1/2">
                  <div class="flat-pickr-label-group">
                    <label class="text-sm ml-1">Abrechnungsende</label>
                    <flat-pickr class="w-full" v-model="accounting.accounting_end"
                                name="accounting_end"
                                placeholder="Datum auswählen"></flat-pickr>
                  </div>
                </div>
              </div>
            </div>
            <div v-else :key="1">

              <mg-select v-model="accounting.client_id" :options="clients" name="client"
                         placeholder="Kunde auswählen"
                         @input="onClientChange"
                         track-by="id" label="company_name" data-vv-as="Kunde" v-validate="'required'">
              </mg-select>
              <span class="text-danger text-sm"
                    v-if="errors.has('client')">{{ errors.first('client') }}</span>

            </div>
          </transition>

          <div class="vx-col w-full mt-4" v-for="(item, index) in clients">
            <div v-if="item.id === accounting.client_id">
                <span v-if="!item.dsgvo_file" class="no-agreement">Der Kunde hat keine AV-VERTRAG!</span>
            </div>
          </div>

          <div class="vx-row flex mt-4">
            <div class="vx-col w-1/2">
              <div class="flat-pickr-label-group">
                <label class="text-sm ml-1">Frühestens Abrechnen ab</label>
                <flat-pickr class="w-full" v-model="accounting.at_least_date_accounting"
                            name="at_least_date_accounting"
                            placeholder="Datum auswählen"></flat-pickr>
              </div>
            </div>
          </div>

        </vx-card>
      </div>

      <div class="vx-col lg:w-1/2">
        <vx-card title="Position" class="h-full">

          <div class="vs-row flex items-center">
            <vs-col vs-w="6" class="pr-4">
              <vs-input label="Produkt-Nr." name="ordernumber" v-model="accounting.ordernumber" readonly
                        class="w-full" data-vv-as="Produkt-Nr." v-validate="'required'"/>
            </vs-col>

            <vs-col vs-w="5" class="pt-4">
              <span class="link" @click="openArticleSearch" color="primary" type="filled">Anderes Produkt auswählen</span>
            </vs-col>

            <vs-col vs-w="1" class="pt-6">
              <div class="hover:text-primary cursor-pointer" @click="openProductDetailModal" v-show="accounting.article_id">
                <feather-icon icon="InfoIcon" class="w-5 h-5"></feather-icon>
              </div>
            </vs-col>
          </div>
          <span class="text-danger text-sm"
                v-if="errors.has('ordernumber')">{{ errors.first('ordernumber') }}</span>

          <vs-row>
            <vs-col vs-w="11">
              <vs-input class="w-full mt-4" label="Produktname*" data-vv-as="Produktname"
                        name="article_name"
                        v-model="accounting.article_name" v-validate="'required|min:3'"/>
              <span class="text-danger text-sm" v-if="errors.has('article_name')">{{ errors.first('article_name') }}</span>
            </vs-col>
            <vs-col vs-w="1" class="pt-12 pl-1">
              <span class="link" @click="openTemplatesPopup" color="primary" type="filled">Vorlagen</span>
            </vs-col>
          </vs-row>


          <label class="vs-input--label mt-4 block">Beschreibung</label>
          <vue-editor name="short_description" v-model="accounting.short_description" :editorToolbar="toolbar"
                      data-vv-as="Beschreibung"/>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" label="Anzahl*" data-vv-as="Anzahl" name="number"
                        v-model="accounting.amount" type="number" step=".25" v-validate="'required'"/>
              <span class="text-danger text-sm"
                    v-if="errors.has('number')">{{ errors.first('number') }}</span>
            </div>
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" label="Einzelpreis*" data-vv-as="Einzelpreis"
                        v-model="accounting.unit_price" name="unit_price"
                        v-validate="'required'" v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }"/>

              <span class="text-danger text-sm" v-if="errors.has('unit_price')">{{ errors.first('unit_price') }}</span>
            </div>
            <div class="vx-col lg:w-1/3">
              <vs-input class="w-full" v-bind:class="{ 'border-red': purchasePriceError }" label="Einkaufspreis"
                        v-model="accounting.ek_price"
                        name="purchase_price"
                        v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }" />
            </div>
          </div>

          <div class="vx-row mt-4">
            <div class="vx-col lg:w-1/2">
              <vs-select label="Einheit" v-model="accounting.volume_unit" class="w-full" @change="onChangeVolumeUnit">
                <vs-select-item :key="index" :value="item.value" :text="item.label"
                                v-for="(item, index) in unitOptions"/>
              </vs-select>
            </div>
            <div class="vx-col lg:w-1/2">

              <vs-select label="MwSt." v-model="accounting.tax" class="w-full">
                <vs-select-item :key="index" :value="item.value" :text="item.label"
                                v-for="(item, index) in taxOptions"/>
              </vs-select>
            </div>
          </div>

          <div class="vx-row mt-8">

            <div class="vx-col w-full flex justify-end ">
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right text-lg font-semibold">
                  Netto Gesamt Preis:
                </div>
                <div class="vx-col w-1/2 text-right text-lg font-semibold">
                  {{sum}} €
                </div>
              </div>
            </div>
            <div class="vx-col flex w-full justify-end mt-2 color-grey">
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right">
                  Brutto Gesamt Preis:
                </div>
                <div class="vx-col w-1/2 text-right">
                  {{taxSum}} €
                </div>
              </div>
            </div>
            <div class="vx-col w-full flex justify-end mt-2" v-if="margePercentFloat != 'NaN'" >
              <div class="vx-row w-1/2">
                <div class="vx-col w-1/2 text-right ">
                  Marge:
                </div>
                <div class="vx-col w-1/2 text-right " v-bind:class="[margePercent < '20'  ? 'color-red' : 'color-green']">
                  {{margeAbs}} € ({{margePercentFloat}} %)
                </div>
              </div>
            </div>
          </div>

        </vx-card>
      </div>
    </div>

    <div class="flex mt-8 justify-end">
      <vs-button color="warning" type="border" class="mb-4" :to="{name :'accountings'}">Abbrechen</vs-button>
      <vs-button class="ml-3 mb-4" @click="saveAccounting">Erstellen</vs-button>
    </div>

    <vs-popup title="Produkt auswählen" :active.sync="popupArticleSearch">
      <p>
        <mg-select :options="articles"
                   track-by="id"
                   label="name"
                   @select="onSelectArticle">

        </mg-select>
      </p>
    </vs-popup>

    <vs-popup title="Textvorlage auswählen" :active.sync="popupArticleNameTemplates">
      <p>
        <mg-select :options="articleNameTemplateOptions"
                   track-by="internal_description"
                   label="internal_description"
                   @select="onSelectArticleNameTemplate">
        </mg-select>
      </p>
    </vs-popup>

    <product-detail-modal :product-id="selectedProductId" :active.sync="productDetailModalOpen"
                          @close="productDetailModalOpen=false"></product-detail-modal>
  </div>
</template>

<script>
import ApiService from "../../../api";
import MgSelect from "../../../components/mg-select/MgSelect";
import staticOptions from "../../../mixins/static/options";
import staticTemplates from "../../../mixins/static/templates";
import PriceHelper from "../../../mixins/helper/price.helper";
import flatPickr from 'vue-flatpickr-component';
import ArticleCustomPricesService from "../../../mixins/services/article-custom-prices.service";
import ProductDetailModal from "../../../components/product/ProductDetailModal";
import {mapGetters} from 'vuex';

export default {
  components: {ProductDetailModal, flatPickr, MgSelect},
  name: "AddAccounting",
  data() {
    return {
      customPriceService: new ArticleCustomPricesService(),
      popupArticleSearch: false,
      popupArticleNameTemplates: false,
      articles: [],
      accounting: {
        workspace_id: null,
        client_id: null,
        article_id: null,
        ordernumber: null,
        at_least_date_accounting: null,
        amount: 1,
        tax: 19,
        volume_unit: 1,
        unit_price: 0,
        purchase_price: 0,
        ek_price: 0,
        article_name: null,
        short_description: null,
        contract_id: null,
        contract_items_id: null,
        contract_start: null,
        contract_end: null,
        accounting_start: null,
        accounting_end: null,
        is_hour_unit: 0,
        unit_multiplicator: 1
      },
      fetched: false,
      clients: [],
      contracts: [],
      type: 1,
      typeOptions: [
        {value: 0, label: 'Anhand eines Vertrages (NICHT BENUTZEN -> Über Vertrag erstellen'},
        {value: 1, label: 'Anhand eines Kunden'}
      ],
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      selectedProductId: null,
      productDetailModalOpen: false,
      oriArticleUnitPrice:0,
      purchasePriceError: false
    }
  },
  created() {
    this.accounting.workspace_id = this.workspace.id
    this.fetchInitialData();
  },
  computed: {
    ...mapGetters([
      'workspace'
    ]),
    taxOptions() {
      return staticOptions.tax;
    },
    unitOptions() {
      if(this.accounting.is_hour_unit) {
        return staticOptions.units.filter(unit => unit.hour_unit);
      }

      return staticOptions.units;
    },
    articleNameTemplateOptions() {
      return staticTemplates.article_name;
    },
    currentUnit() {
      if(!this.accounting.volume_unit) {
        return null;
      }

      return staticOptions.units.find(unit => unit.value == this.accounting.volume_unit);
    },
    taxSum() {
      return PriceHelper.floatPointToComma(PriceHelper.taxPrice(PriceHelper.parseCommaFloat(this.accounting.unit_price), this.accounting.tax) * this.accounting.amount);
    },
    sum() {
      return PriceHelper.floatPointToComma(PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount);
    },
    formValid() {
      return !this.errors.any();
    },
    margeAbs() {
      return PriceHelper.floatPointToComma((PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount) - (PriceHelper.parseCommaFloat(this.accounting.ek_price) * this.accounting.amount));
    },
    margePercent() {
      return this.calcMargePercent();
    },
    margePercentFloat() {
      return PriceHelper.floatPointToComma(this.calcMargePercent());
    },

  },
  methods: {
    calcMargePercent() {
      var sum = PriceHelper.parseCommaFloat(this.accounting.unit_price) * this.accounting.amount;
      var marge =  sum - (PriceHelper.parseCommaFloat(this.accounting.ek_price) * this.accounting.amount);

      return  (marge / sum)*100;
    },
    openProductDetailModal() {
      this.selectedProductId = this.accounting.article_id;
      this.productDetailModalOpen = true;
    },
    fetchInitialData() {
      this.$vs.loading();
      this.fetched = false;

      const contracts = ApiService.get('contracts');
      const clients = ApiService.get('clients');
      const articles = ApiService.get('articles');

      Promise.all([contracts, clients, articles]).then(result => {
        this.$vs.loading.close();

        if (result[0].status !== 200 || result[1].status !== 200) {
          this.$router.push({name: 'accountings'});
        }

        this.contracts = result[0].data.result;
        this.clients = result[1].data.result;
        this.articles = result[2].data.result;

        this.fetched = true;
      });

    },
    onContractChange(contract) {
      this.accounting.contract_start = contract.contract_start;
      this.accounting.contract_end = contract.contract_end;
      this.accounting.client_id = contract.client_id;

      this.refreshCustomerPrices();
    },
    onClientChange() {
      this.refreshCustomerPrices();
    },
    onTypeChange() {
      this.accounting.contract_start = null;
      this.accounting.contract_end = null;
      this.accounting.contract_id = null;
      this.accounting.client_id = null;
    },
    customContractLabel(option) {
      return `#${option.id} ${option.type.name} - ${option.client.company_name}`;
    },
    saveAccounting() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$vs.loading();

          this.accounting.unit_price = PriceHelper.parseCommaFloat(this.accounting.unit_price);
          this.accounting.ek_price = PriceHelper.parseCommaFloat(this.accounting.ek_price);

          ApiService.post('accountings', this.accounting).then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Erfolgreich',
              text: 'Die Abrechnung wurde erfolgreich gespeichert.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            });

            this.$router.push({name: 'accountings'});

          }).catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: 'Fehler',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          });
        }
      });

    },
    getUnitOptionById(id) {
      return this.unitOptions.find(unit => unit.value === id);
    },
    openArticleSearch() {
      this.popupArticleSearch = true;
    },
    openTemplatesPopup() {
      this.popupArticleNameTemplates = true;
    },
    onSelectArticle(article) {
      const customerPrice = this.customPriceService.getPriceByArticleId(article.id);

      this.accounting.article_id = article.id;
      this.accounting.short_description = article.description;
      this.accounting.article_name = article.name;
      this.accounting.unit_price = PriceHelper.floatPointToComma(article.price);
      this.oriArticleUnitPrice = JSON.parse(JSON.stringify(article.price));

      if (customerPrice !== null) {
        this.accounting.unit_price = customerPrice.price;
        this.oriArticleUnitPrice =  JSON.parse(JSON.stringify(customerPrice.price))
      }

      this.accounting.is_hour_unit = article.is_hour_unit;
      this.accounting.unit_multiplicator = 1;

      const unit = this.getUnitOptionById(this.accounting.volume_unit);

      if(unit) {
        this.accounting.unit_multiplicator = unit.multiplicator;
        this.oriArticleUnitPrice =  this.oriArticleUnitPrice / unit.multiplicator;
      }

      this.accounting.ordernumber = article.ordernumber;
      this.accounting.tax = article.tax;
      this.accounting.volume_unit = parseInt(article.unit);

      this.popupArticleSearch = false;

      article.categories.forEach(category => {
        if (category.name === 'SSL-Zertifikate' || category.name === 'Fremdleistung' || category.name === 'Digitales Produkt' || category.name === 'Server/Hosting') {
          this.purchasePriceError = true;
        } else {
          this.purchasePriceError = false;
        }
      })
    },
    onChangeVolumeUnit() {
      const unit = this.getUnitOptionById(this.accounting.volume_unit);

      if(unit) {
        this.accounting.unit_multiplicator = unit.multiplicator;
        this.accounting.unit_price = this.oriArticleUnitPrice * this.accounting.unit_multiplicator;
      }
    },
    onSelectArticleNameTemplate(option) {
      this.accounting.short_description = option.description;
      this.accounting.article_name = option.article_name;
      this.popupArticleNameTemplates = false
    },
    refreshCustomerPrices() {
      let clientId = null;

      this.$vs.loading();

      if (this.accounting.client_id !== null) {
        clientId = this.accounting.client_id;
      }

      if (!clientId) {
        return;
      }

      this.customPriceService.fetchCustomerPrices(clientId).finally(() => {
        this.$vs.loading.close();
      });
    },
  }
}
</script>

<style lang="scss">
.accounting-add {
  .quillWrapper {
    .ql-editor {
      height: 5rem;
      min-height: 5rem;
    }
  }
  .no-agreement {
    background-color: #e24733;
    min-height: 40px;
    width: 100%;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    font-size: 14px;
    color: white;
  }
}

.color-grey {
  color: #d4d4d4;
}
.color-red {
  color:red;
}
.color-green {
  color:green;
}
.border-red {
  .vs-con-input {
    .vs-inputx  {
      &.vs-input--input {
        &.normal {
          border-color: red !important;
        }
      }
    }
  }
}
</style>

